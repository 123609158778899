<template>
  <div class="layout-dashboard">
    <Toast />
    <div class="grid">
      <div class="col-12 lg:col-12">
        <div class="card card-w-title statistics">
          <h5>Facturación</h5>
          <p>Revisa el detalle de tus movimientos y cobros.</p>
          <p>
            Para dudas contáctanos a <b> {{ mail }} </b> o al
            <b> +56 {{ phone }} </b>
          </p>
          <ProgressSpinner
            style="
              width: 50px;
              height: 50px;
              margin-top: 20px;
              margin-left: 40%;
            "
            class="spinner"
            strokeWidth="8"
            fill="var(--surface-ground)"
            animationDuration=".5s"
            v-if="loading"
          />
          <DataTable
            :value="billings"
            showGridlines
            tableStyle="min-width: 50rem; margin-top:20px"
            v-else
          >
            <Column header="Período" field="period" />
            <Column header="Tipo de documento" field="type" />
            <Column header="N° Documento" field="document_number" />
            <Column header="Estado" field="status" />
            <Column header="Ver">
              <template #body="data">
                <Button
                  icon="pi pi-file-pdf"
                  class="icon"
                  @click="getDownloadLink(data.data.document)"
                />

                <Button
                  icon="pi pi-file-excel"
                  @click="viewBillingExcel(data.data)"
                />
              </template>
            </Column>
            <Column header="Total Neto CLP" field="total" />
          </DataTable>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { getBillings } from "@/api/billing";
import { readUserData } from "@/services/user";
import { ref } from "vue";
import * as XLSX from "xlsx";
import { findByUser } from "@/api/customer";
import { useToast } from "primevue/usetoast";
import ProgressSpinner from "primevue/progressspinner";
import { moneyFormatter } from "@/services/utils";
import { formatDateTime } from "../services/utils";

const user = readUserData();
let customer = ref({});
const toast = useToast();
const billings = ref([]);
const loading = ref(false);
const phone = process.env.VUE_APP_SUPPORT_PHONE;
const mail = process.env.VUE_APP_SUPPORT_EMAIL;
const loadBillings = async () => {
  const { data } = await getBillings(customer.value.id);
  billings.value = data;
  billings.value.forEach((billing) => {
    billing.total = moneyFormatter(billing.total);
  });
};

const getCustomerData = async () => {
  try {
    const response = await findByUser(user.id);
    customer.value = response.data;
  } catch (err) {
    showToast("error", "Error", "Ha ocurrido un error al cargar los datos");
  }
};

const showToast = (severity, summary, detail) => {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 7000,
  });
};

const startComponent = async () => {
  loading.value = true;
  await getCustomerData();
  if (!customer.value.id) {
    loading.value = false;
    return;
  }
  await loadBillings();
  loading.value = false;
};

startComponent();

const getDownloadLink = async (document) => {
  const apiUrl = process.env.VUE_APP_API_URL;
  window.open(`${apiUrl}/files/documents/${document}`, "_blank");
};

const viewBillingExcel = (billing) => {
  console.log(billing);
  const billingData = {
    "Fecha de creación": formatDateTime(billing.created_at),
    "Tipo de documento": billing.type,
    "Número de documento": billing.document_number,
    "Costo por envío courier Neto CLP": `$${Number(
      billing.cost_per_courier
    ).toLocaleString("es-CL")}`,
    "Costo por envío ishiper Neto CLP": `$${Number(
      billing.shipping_cost_ishiper
    ).toLocaleString("es-CL")}`,
    "Costo fijo servicio ishiper Neto CLP": `$${Number(
      billing.surcharge
    ).toLocaleString("es-CL")}`,
    Estado: billing.status,
  };

  const shipmentsData = billing.shipments.map((shipment) => {
    return {
      "Número de envío": shipment.delivery_number,
      Estado: shipment.status,
      "Fecha de creación": formatDateTime(shipment.created_at),
      "Fecha compromiso": formatDateTime(shipment.commitment_date),
      "Días de tránsito": shipment.commitment_days,
      "Canal de venta": shipment.sale_channel,
      Destinatario: shipment.shipping_name,
      Dirección: shipment.shipping_address_1,
      Comuna: shipment.shipping_city,
      "Costo por envío courier Neto CLP": `$${Number(
        shipment.cost_per_courier
      ).toLocaleString("es-CL")}`,
      "Costo por envío ishiper Neto CLP": `$${Number(
        shipment.shipping_cost_ishiper
      ).toLocaleString("es-CL")}`,
    };
  });

  const workbook = XLSX.utils.book_new();

  const billingSheet = XLSX.utils.json_to_sheet([billingData]);
  XLSX.utils.book_append_sheet(workbook, billingSheet, "Factura");

  const shipmentsSheet = XLSX.utils.json_to_sheet(shipmentsData);
  XLSX.utils.book_append_sheet(workbook, shipmentsSheet, "Envíos");

  const billingWorksheet = workbook.Sheets["Factura"];
  const billingColumnWidths = [
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 160 },
    { wpx: 160 },
    { wpx: 160 },
    { wpx: 100 },
  ];
  billingWorksheet["!cols"] = billingColumnWidths;

  const shipmentWorksheet = workbook.Sheets["Envíos"];
  const shipmentColumnWidths = [
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 150 },
    { wpx: 150 },
  ];
  shipmentWorksheet["!cols"] = shipmentColumnWidths;

  const outputPath = `doc_excel_${billing.id}.xlsx`;
  XLSX.writeFile(workbook, outputPath);
};
</script>

<style scoped>
img {
  width: 30%;
}
h4 {
  color: rgb(22, 31, 133);
  margin-left: 20px;
}
.icon {
  margin: 0 5px;
}
</style>
